
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';

import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from './../../ng-uikit-pro-standard';

import { Routing } from './app.routing';
import { Ng4LoadingSpinnerModule } from './modules/ng4-loading-spinner-master/src';
import {Ng2TelInputModule} from 'ng2-tel-input';

import { CommonService } from './services/common.service';
import { HeightDirective } from './directives/height.directive';
import {AuthGuardService} from './auth.gard.service'
import { StorageServiceModule} from 'angular-webstorage-service';

@NgModule({
  declarations: [
    AppComponent,
    HeightDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    StorageServiceModule,
    HttpModule,
    Routing,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    AgmCoreModule.forRoot({
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en#key
      apiKey: 'Your_api_key'
    }),
    Ng4LoadingSpinnerModule,
    Ng2TelInputModule
  ],
  providers: [
    MDBSpinningPreloader,
    CommonService,
    AuthGuardService
  ],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
