import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from '../app/auth.gard.service';

export const routes: Routes = [
  { path: '',  loadChildren: './frontend/frontend.module#FrontendModule'},
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule'},
  { path: 'login', loadChildren: './frontend/login/login.module#LoginModule'},
//  { path: 'home', redirectTo: '',loadChildren: './frontend/frontend.module#FrontendModule'}
];

export const Routing: ModuleWithProviders = RouterModule.forRoot(routes);
