import { Injectable } from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
public  isLoggedIn: boolean = true; 
public user :string;
canActivate() {
  //Your redirect logic/condition. I use this.
// var name = localStorage.getItem('logged')
// console.log('11111',name);
  if (this.isLoggedIn) {
           this.router.navigate(['/login']);
           return false;
      }
     // this.router.navigate(['']);
      console.log('AuthGuard#canActivate called');
      return true;
    }
  constructor(
    private router: Router,
    
  ) {}

  // canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
  //   // isLoggedIn: boolean = false; 
  //   if (!this.isLoggedIn) {
  //     return false;
  //   } else {
  //     this.router.navigate(['/home']);
  //     return false;
  //   }
  // }

}