import { Directive } from '@angular/core';

@Directive({
  selector: '[appHeight]'
})
export class HeightDirective {

  constructor() { }

}
